import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Footer from "../components/footer.js"

const Hero = styled("div")`
	&.hero.all-page {
		p {
			font-size: 16px;
		    color: #252525;
		    font-weight: 500;
		}
	}
`

const Container = styled("div")`
	.category {
		ul {
			li {
			    margin-bottom: 20px;
			}
		}
	}
`

export default function SiteMap({data}) {
	return (
		<>
			<Seo title="Карта сайта | SleepAdvisor" description="Карта сайта SleepAdvisor" />
			<Helmet>
				<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Карта сайта"}]}`}</script>
			</Helmet>
			<Menu />
			<Hero className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item active" aria-current="page">Карта сайта</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>Карта сайта</h1>
					{/*<p className="max-w-[540px] md:max-w-[720px] mx-auto">Все мы долгое время проводим в интернете в поисках полезной информации о матрасах, которая поможет выбрать подходящую модель. Наши рейтинги, обзоры и статьи направлены на то, чтобы предоставить полную и актуальную информацию.</p>*/}
				</div>
			</Hero>
			<Container>
				<section className="directory-container py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-[750px] mx-auto px-[15px]">
					<div className="container">
						<div className="category mb-[80px]" id="category">
							<h2 className="text-center">Матрасы по категориям</h2>
							{/*<p className="mb-[50px]">При выборе матраса важно учитывать множество факторов, таких как особенности организма, бюджет или проблемы со спиной. Данные рейтинги помогут понять, как подобрать под ваши особенности основу матраса и его конструкцию. Рейтинг не только опишет все основные типы и категории, но и предоставит собственный топ моделей, на который можно опираться при выборе.</p>*/}
							<div className="grid md:grid-cols-2 gap-4">
								<div className="mb-[40px]">
									<h3>По материалу</h3>
									<ul>
										{data.allContentfulPagesMattress.edges.map(({ node }) => (
											node.category === 'Материал' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
										))}
									</ul>
								</div>
								<div className="mb-[40px]">
									<h3>По конструкции</h3>
									<ul>
										{data.allContentfulPagesMattress.edges.map(({ node }) => (
											node.category === 'Конструкция' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
										))}
									</ul>
								</div>
								<div className="mb-[40px]">
									<h3>По форме</h3>
									<ul>
										{data.allContentfulPagesMattress.edges.map(({ node }) => (
											node.category === 'Форма' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
										))}
									</ul>
								</div>
								<div className="mb-[40px]">
									<h3>По типу</h3>
									<ul>
										<li><a href={'/reiting-matrasov/'}>Лучшие матрасы {(new Date().getFullYear())} года</a></li>
										{data.allContentfulPagesMattress.edges.map(({ node }) => (
											node.category === 'Тип' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
										))}
									</ul>
								</div>
								<div className="mb-[40px]">
									<h3>По цене</h3>
									<ul>
										{data.allContentfulPagesMattress.edges.map(({ node }) => (
											node.category === 'Цена' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
										))}
									</ul>
								</div>
							</div>
						</div>
						<div className="mb-[80px]" id="review">
							<h2 className="text-center">Обзоры на производителей</h2>
							{/*<p className="mb-[50px]">Что вы знаете о производителях матрасов и их линейках производства? Наши обзоры помогут подобрать лучший матрас производителя по каждой из категории, а также разъяснить в чем особенность той или иной модели и какие технологии используются при ее производстве.</p>*/}
							<ul className="grid md:grid-cols-2 gap-4">
								{data.allContentfulBrandRaiting.edges.map(({ node }) => (
									<li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
								))}
								{data.allContentfulPagesMattress.edges.map(({ node }) => (
									node.category === 'Бренд' && <li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
								))}
							</ul>
						</div>
						<div className="mb-[80px]" id="review">
							<h2 className="text-center">Обзоры на матрасы</h2>
							{/*<p className="mb-[50px]">Что вы знаете о производителях матрасов и их линейках производства? Наши обзоры помогут подобрать лучший матрас производителя по каждой из категории, а также разъяснить в чем особенность той или иной модели и какие технологии используются при ее производстве.</p>*/}
							<ul className="grid md:grid-cols-2 gap-4">
								{data.allContentfulMattress.edges.map(({ node }) => (
									node.url && <li><a href={'/'+node.brand.linkBrandPage+'/'+node.url+'/'}>{node.brand.name + ' ' + node.name}</a></li>
								))}
							</ul>
						</div>
						<div id="posts">
							<h2 className="text-center">Статьи</h2>
							{/*<p className="mb-[50px]">Кто не задается вопросами о качественном сне? Множество статей с интересными подробностями о здоровом сне.</p>*/}
							<ul className="grid md:grid-cols-2 gap-4">
								{data.allContentfulBlog.edges.map(({ node }) => (
									<li><a href={'/'+node.url+'/'}>{node.titlePage}</a></li>
								))}
							</ul>
						</div>
					</div>
				</section>
			</Container>
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query {
    	allContentfulBlog(sort: { fields: [createdAt], order: DESC }) {
      		edges {
	        	node {
	          		id
	          		url
	          		titlePage
	        	}
	      	}
    	}

    	allContentfulPagesMattress(sort: { fields: [createdAt], order: ASC }) {
	    	edges {
	      		node {
	        		id
	        		category
	        		titlePage
	        		url
	      		}
	    	}
	  	}

	  	allContentfulBrandRaiting {
	  		edges {
	      		node {
	        		id
	        		titlePage
	        		url
	      		}
	    	}
	  	}

	  	allContentfulMattress {
	  		edges {
	      		node {
	        		id
	        		name
	        		url
	        		brand {
	        			__typename
        				... on ContentfulBrand {
        					id
    						name
    						linkBrandPage
        				}
	        		}
	      		}
	    	}
	  	}
  	}
`